
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageErrorComponent } from './shared/components/page-error/page-error.component';

const routes: Routes = [
  {
    path: 'auth' ,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'agenda',
    loadChildren: () => import('./modulos/agenda/agenda.module').then(m => m.AgendaModule)
  },
  {
    path: 'catalogos',
    loadChildren: () => import('./modulos/catalogos/catalogos.module').then(m => m.CatalogosModule)
  },
  {
    path: 'cliente-nuevo',
    loadChildren: () => import('./modulos/cliente-nuevo/cliente-nuevo.module').then(m => m.ClienteNuevoModule)
  },
  {
    path: 'notfound',
    component: PageErrorComponent
  },
  {
    path: '**',
    redirectTo: 'auth/',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
